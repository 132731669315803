<template>
    <div>
        <open-head title="专题研究报告" content="报告来自科技管理服务资深从业人员，快来仪器讨论学习吧"></open-head>
        
        <div class="content">
            <Breads :breads="breads"></Breads>
        </div>
        <div class="content report">
            <div class="left-content">
                <Menu title="科技创新平台" :items="items"></Menu>
                <Menu title="创新要素资源" :items="items"></Menu>
                <Menu title="仪器开放共享" :items="items"></Menu>


               
            </div>
            <div class="right-content">
                <div class="btns">
                    <button type="button" class="btn btn-primary" @click="gotoReportDetail">在线阅读</button>
                    <button type="button" class="btn btn-outline">收藏</button>
                    <button type="button" class="btn btn-outline">分享</button>
                </div>
                <div class="content-desc">
                     <p>这是内容</p>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>
import Breads from '../../components/common/Bread';
import OpenHead from '../../components/open/OpenHead';
import Menu from '../../components/open/Menu'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components:{Breads, OpenHead, Menu},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '专题研究报告',
        }]);

        const menu1Active = ref(-1);
        const menu2Active = ref(-1);
        const menu3Active = ref(-1);

        function menu1Change(index){
            menu1Active.value = index;
            menu2Active.value = -1;
            menu3Active.value = -1;
        }

        function menu2Change(index){
            menu1Active.value = -1;
            menu2Active.value = index;
            menu3Active.value = -1;
        }

        function menu3Change(index){
            menu1Active.value = -1;
            menu2Active.value = -1;
            menu3Active.value = index;
        }

        return {breads, menu1Active,menu2Active, menu3Active,menu1Change, menu2Change, menu3Change}
    },
    data(){
        return {
            items: [{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}
            ,{name:'广东省重点实验室科研产出', date:'2021年2月'}]
        }
    },
    methods:{
        gotoReportDetail(){
            this.$router.push({name: 'ReportDetail', path: '/reportDetail'})
        }
    }
}
</script>

<style lang="less" scoped>
.report{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.left-content{
    width: 283px;
    margin-right: 22px;
    margin-bottom: 189px;
    
}
.right-content{
    position: relative;
    flex:1;
    margin-bottom: 74px;

    .btns{
        position: absolute;
        top: 0;
        right: 0;

        .btn{
            width: 90px;
            margin-bottom: 7px;
        }
    }
    .content-desc{
        margin-right: 112px;
    }
}
</style>

