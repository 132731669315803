<template>
    <div class="content">
        <Breads :breads="breads"></Breads>
        <div class="intro-content about-box" v-html="content">
            
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
import Breads from '../../components/common/Bread';

export default {
    components:{Breads},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '科技平台简介',
        }]);

        const content = ref('');
        const loading = ref(false)
        const {proxy} = getCurrentInstance();

        function getContent(){
            loading.value = true;
            const url = '/hfapplication/gdsti/a/openService/sciencePlatformBriefIntroduction';
            proxy.$http.post(url).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    content.value = res.data.result.content;
                }
            }).catch(err=>{
                loading.value = false
            })
        }
        
        onMounted(()=>{
            getContent();
        })

        return {breads, loading, content}
    }
}
</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');
.intro-content{
    padding-top: 25px;
    padding-bottom:97px;
    .intro-img{
        text-align: center;
    }
}
</style>

